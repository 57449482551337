.flyout {
    z-index: $z-index-flyout;

    div>a {
        display: flex;
        justify-content: center;
        align-items: center;

        &[data-badge]::before {
            content: attr(data-badge);
            position: absolute;
            top: 0.25rem;
            right: -0.5rem;
            background: $clr-dark-grey;
            color: $clr-white;
            font-size: .75rem;
            display: flex;
            justify-content: center;
            align-content: center;
            border-radius: 100%;
            width: 1.25rem;
            height: 1.25rem;
            line-height: 1.25rem;
            font-weight: bold;
            box-shadow: 0 0 3px rgba($clr-black, 0.3);
        }

        &[hidden] {
            display: none;
        }

    }

    .flyout-icon {
        width: 1.25rem;
        height: 1.25rem;
        background-repeat: no-repeat;
        background-position: center;
    }

    [data-flyout="search"] .flyout-icon {
        background-image: url('data:image/svg+xml;utf8,<!-- Generator: Adobe Illustrator 25.4.2, SVG Export Plug-In . SVG Version: 6.00 Build 0)  --> <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 57 57" style="enable-background:new 0 0 57 57;" xml:space="preserve"> <path d="M56.1,52.97l-3.14,3.14c-1.03,1.05-2.72,1.05-3.77,0L38.11,45.03c-0.5-0.5-0.78-1.18-0.78-1.89v-1.81 c-3.92,3.07-8.86,4.89-14.22,4.89C10.34,46.22,0,35.88,0,23.11C0,10.35,10.34,0,23.11,0s23.11,10.35,23.11,23.11 c0,5.37-1.82,10.3-4.89,14.22h1.81c0.71,0,1.39,0.28,1.89,0.78l11.08,11.08C57.14,50.24,57.14,51.92,56.1,52.97z M37.33,23.11 c0-7.87-6.37-14.22-14.22-14.22c-7.87,0-14.22,6.37-14.22,14.22c0,7.87,6.37,14.22,14.22,14.22C30.98,37.33,37.33,30.97,37.33,23.11 z"/> </svg>');
    }

    [data-flyout="contact"] .flyout-icon {
        background-image: url('data:image/svg+xml;utf8,<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 50" style="enable-background:new 0 0 64 50;" xml:space="preserve"> <path d="M64,5.33v39.11c0,2.94-2.39,5.33-5.33,5.33H5.33C2.39,49.78,0,47.39,0,44.44V5.33C0,2.39,2.39,0,5.33,0h53.33 C61.61,0,64,2.39,64,5.33z M32,34.84c0-3.53-3.34-6.4-7.47-6.4h-0.56c-1.36,0.56-2.86,0.89-4.42,0.89s-3.06-0.32-4.42-0.89h-0.56 c-4.12,0-7.47,2.87-7.47,6.4v2.13c0,1.18,1.11,2.13,2.49,2.13h19.91c1.38,0,2.49-0.96,2.49-2.13V34.84z M12.44,17.78 c0,3.92,3.19,7.11,7.11,7.11s7.11-3.19,7.11-7.11s-3.19-7.11-7.11-7.11S12.44,13.86,12.44,17.78z M56.89,15.11 c0-0.49-0.4-0.89-0.89-0.89H40c-0.49,0-0.89,0.4-0.89,0.89v1.78c0,0.49,0.4,0.89,0.89,0.89h16c0.49,0,0.89-0.4,0.89-0.89V15.11z M56.89,22.22c0-0.49-0.4-0.89-0.89-0.89H40c-0.49,0-0.89,0.4-0.89,0.89V24c0,0.49,0.4,0.89,0.89,0.89h16c0.49,0,0.89-0.4,0.89-0.89 V22.22z M56.89,29.33c0-0.49-0.4-0.89-0.89-0.89H40c-0.49,0-0.89,0.4-0.89,0.89v1.78c0,0.49,0.4,0.89,0.89,0.89h16 c0.49,0,0.89-0.4,0.89-0.89V29.33z"/> </svg>');
    }

    [data-flyout="mail"] .flyout-icon {
        background-image: url('data:image/svg+xml;utf8,<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 55 55" style="enable-background:new 0 0 55 55;" xml:space="preserve"> <path d="M55.11,24.89c0,8.42-4.6,16.38-16.7,16.38c-3.34,0-6.8,0-7.86-3.79c-1.96,2.52-5.33,4.26-8.33,4.26 c-6.58,0-10.67-4.51-10.67-11.76c0-9.92,7.03-16.83,15.31-16.83c2.17,0,5.03,0.43,6.66,2.42l0-0.01c0.08-0.75,0.72-1.33,1.49-1.33h5 c1.68,0,2.94,1.53,2.62,3.18l-2.6,13.45c-0.38,1.91-0.43,3.29,1.5,3.34c4.12-0.44,6.46-4.99,6.46-9.3 c0-11.37-9.32-17.78-20.44-17.78c-11.27,0-20.44,9.17-20.44,20.44S16.28,48,27.56,48c3.92,0,7.77-1.14,11.05-3.24 c1.13-0.72,2.63-0.47,3.49,0.57l1.13,1.38c1.01,1.23,0.72,3.06-0.62,3.93c-4.45,2.9-9.69,4.47-15.05,4.47 C12.32,55.11,0,42.79,0,27.56S12.33,0,27.56,0C42.89,0,55.11,9.74,55.11,24.89z M31.61,25.75c0-2.84-1.48-4.53-3.96-4.53 c-3.09,0-6.51,3.08-6.51,8.08c0,2.8,1.54,4.53,4.01,4.53C27.84,33.83,31.61,30.75,31.61,25.75z"/> </svg>');
    }

    [data-flyout="planerline"] .flyout-icon {
        background-image: url('data:image/svg+xml;utf8,<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 57 50" style="enable-background:new 0 0 57 50;" xml:space="preserve"> <path d="M56.89,23.11c0,12.77-12.73,23.11-28.44,23.11c-4.27,0-8.3-0.79-11.93-2.15c-2.73,2.18-8.26,5.71-15.62,5.71 c-0.36,0-0.67-0.2-0.81-0.53c-0.14-0.33-0.08-0.71,0.17-0.97c0.06-0.06,4.7-5.04,6.09-10.64C2.38,33.67,0,28.62,0,23.11 C0,10.35,12.73,0,28.44,0S56.89,10.35,56.89,23.11z"/> </svg>');
    }

    [data-flyout="catalog-create"] .flyout-icon {
        background-image: url('data:image/svg+xml;utf8,<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 57 57" style="enable-background:new 0 0 57 57;" xml:space="preserve"> <path d="M1.38,26.21l23.76-10.77C26.59,24.81,34.67,32,44.44,32c1.86,0,3.63-0.32,5.33-0.81v2.06l-20.15,9.14 c-0.75,0.34-1.61,0.34-2.36,0L1.38,30.65C-0.46,29.82-0.46,27.04,1.38,26.21z M55.51,44.9L29.63,56.63c-0.75,0.34-1.61,0.34-2.36,0 L1.38,44.9c-1.84-0.83-1.84-3.61,0-4.44l6.5-2.95l17.91,8.12c0.84,0.38,1.73,0.57,2.65,0.57c0.92,0,1.81-0.19,2.65-0.57l17.91-8.12 l6.5,2.95C57.35,41.29,57.35,44.06,55.51,44.9z M32,14.22v-3.56c0-0.98,0.8-1.78,1.78-1.78h7.11V1.78c0-0.98,0.8-1.78,1.78-1.78 h3.56C47.2,0,48,0.8,48,1.78v7.11h7.11c0.98,0,1.78,0.8,1.78,1.78v3.56c0,0.98-0.8,1.78-1.78,1.78H48v7.11 c0,0.98-0.8,1.78-1.78,1.78h-3.56c-0.98,0-1.78-0.8-1.78-1.78V16h-7.11C32.79,16,32,15.2,32,14.22z"/> </svg>');
    }

    [data-flyout="favorites"] .flyout-icon {
        background-image: url('data:image/svg+xml;utf8,<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 60 57" style="enable-background:new 0 0 60 57;" xml:space="preserve"> <path d="M32.91,1.98l7.26,14.71l16.23,2.37c2.91,0.42,4.08,4.01,1.97,6.07L46.62,36.57l2.78,16.17c0.5,2.92-2.58,5.1-5.16,3.74 l-14.52-7.63L15.2,56.48c-2.58,1.37-5.66-0.82-5.16-3.74l2.78-16.17L1.08,25.13c-2.11-2.06-0.94-5.65,1.97-6.07l16.23-2.37 l7.26-14.71C27.84-0.68,31.61-0.64,32.91,1.98z"/> </svg>');
    }

    [data-flyout="product-compare"] .flyout-icon {
        background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="body_1" x="0px" y="0px" viewBox="0 0 45 34" xml:space="preserve"> <g transform="matrix(0.06640625 0 0 0.06640625 7.624999 -0)"> <path d="M416 208C 433.67 208 448 193.67 448 176L448 176L448 144C 448 126.33 433.67 112 416 112L416 112L392.12 112L443.99 45.190002C 449.36 38.170002 448.03 28.130003 441.02 22.760002L441.02 22.760002L415.61 3.3C 408.59 -2.0800002 398.55 -0.74 393.16998 6.27L393.16998 6.27L311.09 112L32 112C 14.33 112 0 126.33 0 144L0 144L0 176C 0 193.67 14.33 208 32 208L32 208L236.56 208L162.03 304L32 304C 14.33 304 0 318.33 0 336L0 336L0 368C 0 385.67 14.33 400 32 400L32 400L87.490005 400L35.620007 466.81C 30.250008 473.82 31.580006 483.86 38.590008 489.24L38.590008 489.24L64 508.7C 71.02 514.08 81.06 512.74 86.43 505.73L86.43 505.73L168.52 400L416 400C 433.67 400 448 385.67 448 368L448 368L448 336C 448 318.33 433.67 304 416 304L416 304L243.05 304L317.58002 208L416 208z" /> </g> </svg>');
    }

    [data-flyout="product-replace"] .flyout-icon {
        background-image: url('data:image/svg+xml;utf8,<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 57 52" style="enable-background:new 0 0 57 52;" xml:space="preserve"> <path d="M8.14,30.34c0.48,1.02,0.27,2.24-0.53,3.03c-0.53,0.52-0.96,0.95-1.32,1.31c-1.26,1.26-3.4,0.96-4.23-0.62 C0.72,31.55-0.02,28.68,0,25.65C0.07,15.85,8.16,8,17.96,8h17.6V2.73c0-2.48,2.89-3.61,4.55-1.95L49,9.67 c1.04,1.04,1.04,2.73,0,3.77l-8.89,8.89c-1.65,1.65-4.55,0.55-4.55-1.94v-5.28H17.9c-5.87,0-10.73,4.69-10.79,10.56 C7.1,27.34,7.46,28.93,8.14,30.34z M56.89,25.92c-0.07,9.8-8.16,17.64-17.95,17.64h-17.6v5.28c0,2.5-2.91,3.58-4.55,1.94l-8.89-8.89 c-1.04-1.04-1.04-2.73,0-3.77l8.89-8.89c1.68-1.68,4.55-0.48,4.55,1.89v5.33h17.65c5.87,0,10.73-4.69,10.79-10.56 c0.02-1.67-0.35-3.26-1.02-4.67c-0.48-1.02-0.27-2.24,0.53-3.03c0.53-0.52,0.96-0.95,1.32-1.31c1.26-1.26,3.4-0.96,4.23,0.62 C56.17,20.01,56.91,22.87,56.89,25.92z"/> </svg>');
    }
}

@include bp-max-tablet {
    .flyout {
        z-index: $z-index-flyout-mobile;
        background: $clr-crystal-white;

        &>button {
            display: none;
        }

        &>div {
            max-width: 750px;
            margin: 0 auto;
            height: 50px;
            display: flex;
            column-gap: 1rem;
            padding: 0 15px;
        }


        div>a {
            color: $clr-lighter-grey;
            font-size: 1.5rem;
            flex: 0 1 auto;
            position: relative;

            &:first-of-type {
                color: $clr-dark-grey;
            }

            &>div {
                display: none;
            }
        }
    }
}

@include bp-medium {
    .flyout {
        position: fixed;
        top: 50vH;
        right: 0;
        transform: translateY(-50%);
        background: $clr-technical-yellow;

        &>button {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(-100%, -50%);
            border: none;
            width: 2rem;
            height: 3rem;
            background: $clr-technical-yellow;

            span {
                font-weight: bold;
            }
        }

        div>a {
            width: 5rem;
            height: 5rem;
            position: relative;


            &:not(&:first-of-type)::after {
                content: "";
                display: block;
                background: $clr-black;
                position: absolute;
                top: -1px;
                height: 2px;
                width: calc(100% - 2rem);
            }

            &>div {
                position: absolute;
                right: 100%;
                font-size: 0.8rem;
                color: $clr-white;
                background: $clr-dark-grey;
                height: 1.5rem;
                line-height: 1.5rem;
                box-shadow: 0 0 5px rgba($clr-black, 0.3);
                padding: 0 0.5rem;
                opacity: 0;
                pointer-events: none;
                white-space: nowrap;

                &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    left: 100%;
                    top: 0;
                    border: 0.75rem solid transparent;
                    border-left-color: $clr-dark-grey;
                }
            }

            &:hover>div {
                opacity: 1;
            }


            &[data-badge]::before {
                top: 0.5rem;
                right: 0.5rem;
                width: 1.5rem;
                height: 1.5rem;
                line-height: 1.5rem;
            }
        }

        &[data-visible="true"] {
            right: 0;
            transition: right 500ms ease;

            button {
                .icon-arrow-left {
                    display: none;
                }
            }
        }

        &[data-visible="false"] {
            right: -5rem;
            transition: right 500ms ease;

            button {
                .icon-arrow-right {
                    display: none;
                }
            }
        }
    }
}