@font-face {
    font-family: "Noto Sans";
    src: url("../fonts/NotoSans-Regular.woff") format("woff"),
    url("../fonts/NotoSans-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "NotoBold";
    src: url("../fonts/NotoSans-Bold.woff") format("woff"),
    url("../fonts/NotoSans-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "NotoBoldItalic";
    src: url("../fonts/NotoSans-BoldItalic.woff") format("woff"),
    url("../fonts/NotoSans-BoldItalic.ttf") format("truetype");
}

@font-face {
    font-family: "NotoItalic";
    src: url("../fonts/NotoSans-Italic.woff") format("woff"),
    url("../fonts/NotoSans-Italic.ttf") format("truetype");
}