.faq-block {
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 20px;
    >span {
        float: left;
        margin-right: 10px;
        margin-top: 3px;
        font-size: 15px;
    }
    .toggle-icon {
        display: inline-block;
        @include prefix(( transition: all 0.5s), webkit moz);
    }
    .faq-question {
        cursor: pointer;
    }
    .faq-question p {
        overflow: hidden;
    }
    .faq-answer {
        display: none;
        margin-left: 25px;
    }
    .green-textlink span {
        font-size: 12px;
        margin-right: 5px;
    }
}

.page-module a.green-textlink {
    color: $clr-wilo-green;
}

.page-module .faq-question p {
    font-weight: bold;
    @include font-size(16px);
    color: $clr-text-darkblue;
}

.green-link {
    @include font-size(16px);
    color: $clr-wilo-green !important;
}

.green-link .wilo-icon {
    font-size: 10px !important;
    margin-right: 0;
    margin-top: 4px;
}

.media-downloads-marginal-wrapper {
    .marginal-sub-headline {
        margin-bottom: 0;
    }
    .link-list {
        margin-top: 0;
    }
}